import React from "react";
import {Section, SectionContainer} from "../assets/components/section";
import Grid from "@mui/material/Unstable_Grid2";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {NewsContent, NewsCover, NewsSubtitle, NewsSubtitle2, NewsTitle} from "../assets/routes/news";

function NewsPage5() {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <>
      <NewsCover backgroundUrl='/images/news/5-1.png'>
      </NewsCover>
      <SectionContainer noCover={false}>
        <Section>
          <Grid spacing={{xs: 12, sm: 12}} container>
            <Grid md={12}>
              <NewsSubtitle>
                News & Events
              </NewsSubtitle>
              <NewsSubtitle2>
                APRIL
              </NewsSubtitle2>
              <NewsTitle mobileMarginTop={10} marginTop={20}>
                최고의 스테이크 하우스 청담 볼트82에서 조선양봉을 만나보세요.
              </NewsTitle>
              <NewsContent mobileMarginTop={10} marginTop={40}>
                Vault82 x Josun Yangbong<br/>
                <br/>
                스테이크의 본고장 미국이 인정한 그 맛, 7년 연속 미슐랭 가이드에 오른 정통 스테이크 하우스와 한국 최고의 클래식 위스키 바를 자랑하는 청담 vault82에서 조선양봉의 제품이 전시
                중입니다.<br/>
                <br/>
                최고의 공간 vault82에서 조선양봉을 만나보세요!<br/>
              </NewsContent>
            </Grid>
          </Grid>
        </Section>
      </SectionContainer>
    </>
  )
}

export default NewsPage5;
