import React from "react";
import {Section, SectionContainer} from "../assets/components/section";
import Grid from "@mui/material/Unstable_Grid2";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {NewsContent, NewsCover, NewsSubtitle, NewsSubtitle2, NewsTitle} from "../assets/routes/news";

function NewsPage1() {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <>
      <NewsCover backgroundUrl='/images/news/1-1.png'>
      </NewsCover>
      <SectionContainer noCover={false}>
        <Section>
          <Grid spacing={{xs: 12, sm: 12}} container>
            <Grid md={12}>
              <NewsSubtitle>
                News & Events
              </NewsSubtitle>
              <NewsSubtitle2>
                OCTOBER
              </NewsSubtitle2>
              <NewsTitle mobileMarginTop={10} marginTop={20}>
                조선양봉과 함께 즐거운 추석 보내세요.
              </NewsTitle>
              <NewsContent mobileMarginTop={10} marginTop={40}>
                이번 추석,<br/>
                조선양봉과 함께 해주셔서 감사합니다.<br/>
                보내주신 관심과 사랑에 무한한 감사의 말씀을 드리며<br/>
                대한민국 최고의 벌꿀 브랜드라는 자부심을 갖고<br/>
                고객님께 더 큰 감동을 드리는 조선양봉이 되겠습니다.<br/>
                <br/>
                조선양봉과 함께 달콤하고 풍성한 추석 보내세요!<br/>
              </NewsContent>
            </Grid>
          </Grid>
        </Section>
      </SectionContainer>
    </>
  )
}

export default NewsPage1;
