import React from "react";
import {Section, SectionContainer} from "../assets/components/section";
import Grid from "@mui/material/Unstable_Grid2";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {NewsContent, NewsCover, NewsSubtitle, NewsSubtitle2, NewsTitle} from "../assets/routes/news";

function NewsPage2() {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <>
      <NewsCover backgroundUrl='/images/news/2-1.png'>
      </NewsCover>
      <SectionContainer noCover={false}>
        <Section>
          <Grid spacing={{xs: 12, sm: 12}} container>
            <Grid md={12}>
              <NewsSubtitle>
                News & Events
              </NewsSubtitle>
              <NewsSubtitle2>
                SEPTEMBER
              </NewsSubtitle2>
              <NewsTitle mobileMarginTop={10} marginTop={20}>
                조선양봉 롯데백화점 입점!
              </NewsTitle>
              <NewsContent mobileMarginTop={10} marginTop={40}>
                대한민국 대표 벌꿀 브랜드 조선양봉이 엄격한 품질관리와 검수를 거쳐 롯데백화점에 입점하게 되었습니다.<br/>
                <br/>
                이번 추석 롯데백화점 추석 마중 GIFT 특선 기획전에서 조선양봉으로 소중한 마음을 전하세요.<br/>
              </NewsContent>
            </Grid>
          </Grid>
        </Section>
      </SectionContainer>
    </>
  )
}

export default NewsPage2;
