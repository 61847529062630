import React from "react";
import {Section, SectionContainer} from "../assets/components/section";
import Grid from "@mui/material/Unstable_Grid2";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {NewsContent, NewsCover, NewsSubtitle, NewsSubtitle2, NewsTitle} from "../assets/routes/news";

function NewsPage7() {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <>
      <NewsCover backgroundUrl='/images/news/7-1.png'>
      </NewsCover>
      <SectionContainer noCover={false}>
        <Section>
          <Grid spacing={{xs: 12, sm: 12}} container>
            <Grid md={12}>
              <NewsSubtitle>
                News & Events
              </NewsSubtitle>
              <NewsSubtitle2>
                JANUARY
              </NewsSubtitle2>
              <NewsTitle mobileMarginTop={10} marginTop={20}>
                조선양봉의 첫 앰배서더 최나경 플루티스트님과 함께.
              </NewsTitle>
              <NewsContent mobileMarginTop={10} marginTop={40}>
                '클래식 역사상 역대 최고의 플루티스트 10인'에 선정된<br/>
                월드클래스 플루티스트 최나경(Jasmine Choi) 님께서 조선양봉과 함께해 주셨습니다.<br/>
                <br/>
                자연 그대로의 방식으로 숙성되어 얻게 되는 조선양봉의 꿀은 그 어느 꿀보다 풍부한 양질의 천연성분과 비타민, 미네랄 그리고 각종 영양분이 체내에 빠른 흡수로 피로를 해소하고 면역력을
                강화해주며 집중력을 향상시켜 줍니다.<br/>
                <br/>
                한국의 위상을 널리 알리며 한국을 빛내고 계신 월드클래스 최나경 플루티스트님의 완벽한 하모니를 위해 대한민국 최고의 꿀, 조선양봉은 늘 장인 정신으로 최고의 꿀만을 고집하며
                제공하겠습니다.<br/>
                <br/>
                조선양봉과 함께 새해 복 많이 받으세요!<br/>
              </NewsContent>
            </Grid>
          </Grid>
        </Section>
      </SectionContainer>
    </>
  )
}

export default NewsPage7;
