import React from "react";
import {CardActionArea, CardMedia} from "@mui/material";
import StyledCard, {
  CardButton,
  CardDate,
  CardText,
  CardTitle,
  StyledCardActions,
  StyledCardContent
} from "../assets/components/card";
import {ArrowForward} from "@mui/icons-material";

function NewsCard(props) {
  const {image, title, content, date, onClick} = props;
  return (
    <StyledCard>
      <CardActionArea onClick={onClick}>
        <CardMedia
          component="img"
          height="250"
          image={image}
        />
        <StyledCardContent>
          <CardTitle>
            {title}
          </CardTitle>
          <CardText dangerouslySetInnerHTML={{__html: content}}>
          </CardText>
        </StyledCardContent>
      </CardActionArea>
      <StyledCardActions>
        <CardDate>
          {date}
        </CardDate>
        <CardButton onClick={onClick}>
          READ MORE <ArrowForward style={{fontSize: "12px"}}/>
        </CardButton>
      </StyledCardActions>
    </StyledCard>
  )
}

export default NewsCard;
