import styled from "styled-components";
import Button from "@mui/material/Button";

const StyledButton = styled(Button)`
  width: ${({width}) => width ? `${width}px` : '100%'};
  background-color: transparent;
  height: 60px;
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
  border-color: ${({theme}) => theme.colors.lightBrown};
  padding-left: ${({theme}) => theme.button.paddingX};
  padding-right: ${({theme}) => theme.button.paddingX};

  color: ${({theme}) => theme.colors.lightBrown};
  font-weight: 700;
  font-size: ${({theme}) => theme.fontSizes.xsPx};
  font-family: "Nanum Gothic", sans-serif;

  box-shadow: none;

  &:hover {
    background-color: ${({theme}) => theme.colors.lightBrown};
    color: ${({theme}) => theme.colors.brown};
    box-shadow: none;
    transition: background-color 0.3s ease-out 10ms;
  }

  @media ${({theme}) => theme.device.mobileL} {
    height: 40px;
    padding-left: ${({theme}) => theme.button.mobilePaddingX};
    padding-right: ${({theme}) => theme.button.mobilePaddingX};
    font-size: ${({theme}) => theme.fontSizes.xxsPx};
  }
`;

export default StyledButton;
