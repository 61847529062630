import styled from "styled-components";

const Cover = styled.div`
  height: 600px;
  background-color: ${({theme}) => theme.colors.black};
  background-image: url(${process.env.PUBLIC_URL}${({backgroundUrl}) => backgroundUrl});
  background-size: cover;
  background-position: center;
  position: relative;
  padding: ${({theme}) => `0 ${theme.paddings.width}`};

  @media ${({theme}) => theme.device.tablet} {
    height: ${({mobileHeight}) => mobileHeight ? `${mobileHeight}px` : "640px"};
    background-position: ${({isIndex}) => isIndex ? "right -200px bottom -650px" : "left top"};
  }
`;

const CoverTextWrapper = styled.div`
  position: absolute;
  left: ${({theme}) => theme.paddings.width};
  bottom: 75px;

  @media ${({theme}) => theme.device.tablet} {
    left: 20px;
    bottom: 28px;
    width: calc(100% - 40px);
  }
`;

const CoverTitle = styled.p`
  font-weight: 700;
  font-size: ${({theme}) => theme.fontSizes.xxlPx};
  line-height: ${({theme}) => theme.lineHeights.xxlPx};
  color: ${({theme}) => theme.colors.white};

  > b {
    color: ${({theme}) => theme.colors.lightBrown};
  }

  @media ${({theme}) => theme.device.tablet} {
    font-size: ${({theme}) => theme.fontSizes.xlPx};
  }
`;

const CoverDescription = styled.p`
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: 400;
  font-size: ${({theme}) => theme.fontSizes.basePx};
  line-height: ${({theme}) => theme.lineHeights.basePx};
  color: ${({theme}) => theme.colors.white};

  @media ${({theme}) => theme.device.tablet} {
    margin-top: 16px;
    margin-bottom: 28px;
    font-size: ${({theme}) => theme.fontSizes.xsPx};
  }
`;

export {
  Cover,
  CoverTextWrapper,
  CoverTitle,
  CoverDescription,
}
