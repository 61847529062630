import React from "react";
import FooterWrapper, {FooterContent, FooterContent2, Logo} from "../../assets/components/layouts/footer";
import Grid from "@mui/material/Unstable_Grid2";

function Footer() {
  return (
    <FooterWrapper>
      <Grid container>
        <Grid xs={12} sm={12} md={2} item container justify="center">
          <Logo src="/images/logo/logo-text-white.png"/>
        </Grid>
        <Grid md={5}>
          <FooterContent>
            <b>상호명 :</b> 조선양봉(JSYB)<br/>
            <b>대표자 :</b> 안성봉<br/>
            <b>주소 :</b> 4-4501, 13, Nonhyeon-ro 132-gil, Gangnam-gu, Seoul, Republic of Korea<br/>
          </FooterContent>
        </Grid>
        <Grid md={5}>
          <FooterContent>
            <b>고객센터 :</b> 070-7626-2323<br/>
            <b>사업자등록번호 :</b> 811-09-02094<br/>
            <b>통신판매업 신고번호 :</b> 2023-서울강남 05763<br/>
            <b>E-mail :</b> josunyangbong@gmail.com
          </FooterContent>
        </Grid>
        <Grid mdOffset={2}>
          <FooterContent2>
            ©2023 JOSUNYANGBONG Inc. All rights reserved.
          </FooterContent2>
        </Grid>
      </Grid>
    </FooterWrapper>
  )
}

export default Footer;
