import React from "react";
import {Section, SectionContainer} from "../assets/components/section";
import Grid from "@mui/material/Unstable_Grid2";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {NewsContent, NewsCover, NewsSubtitle, NewsSubtitle2, NewsTitle} from "../assets/routes/news";

function NewsPage6() {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <>
      <NewsCover backgroundUrl='/images/news/6-1.png'>
      </NewsCover>
      <SectionContainer noCover={false}>
        <Section>
          <Grid spacing={{xs: 12, sm: 12}} container>
            <Grid md={12}>
              <NewsSubtitle>
                News & Events
              </NewsSubtitle>
              <NewsSubtitle2>
                APRIL
              </NewsSubtitle2>
              <NewsTitle mobileMarginTop={10} marginTop={20}>
                조선양봉 팝업스토어 공지.
              </NewsTitle>
              <NewsContent mobileMarginTop={10} marginTop={40}>
                성수동 논더레스에서 조선양봉의 첫 팝업스토어가 열립니다.<br/>
                <br/>
                일시 : 2023. 4. 8. ~ 2023. 4. 9.<br/>
                <br/>
                이번 팝업에서는 조선양봉의 최고급 자연 숙성 천연 벌꿀<br/>
                금(아카시아 꿀), 성(야생화 꿀), 옥(옻 꿀), 진(밤 꿀)등을 직접 만나보고 시식하실 수 있으며 기간 내 할인 이벤트도 진행 예정입니다.<br/>
                <br/>
                조선양봉 외에도 각 셀러들이 준비한 (COMME TOI, P.U.P, 긱스의 루이, 유성은, 에이머, 윤담백) 다양한 제품들을 함께 만나보실 수 있습니다.<br/>
                <br/>
                다가오는 봄 조선양봉과 함께 달콤하세요~!<br/>
              </NewsContent>
            </Grid>
          </Grid>
        </Section>
      </SectionContainer>
    </>
  )
}

export default NewsPage6;
