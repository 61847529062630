import styled from "styled-components";
import {Swiper} from "swiper/react";

const JSSwiper = styled(Swiper)`
  margin-top: ${({marginTop}) => marginTop ? `${marginTop}px` : 0};
  margin-right: ${({marginRight}) => marginRight ? `${marginRight}px` : 0};
  margin-bottom: ${({marginBottom}) => marginBottom ? `${marginBottom}px` : 0};
  cursor: grab;
  @media ${({theme}) => theme.device.mobileL} {
    margin-top: 20px;
    margin-right: ${({mobileMarginRight}) => mobileMarginRight ? `${mobileMarginRight}px` : "-10px"};
    margin-left: ${({mobileMarginLeft}) => mobileMarginLeft ? `${mobileMarginLeft}px` : "-10px"};
    margin-bottom: ${({mobileMarginBottom}) => mobileMarginBottom ? `${mobileMarginBottom}px` : "0"};
  }
`;

const SwiperImage = styled.img`
  width: 100%;
`;

const SwiperContent = styled.div`
  padding-top: 60px;
  font-weight: 400;
  font-size: ${({theme}) => theme.fontSizes.base};
  line-height: ${({theme}) => theme.lineHeights.base};

  @media ${({theme}) => theme.device.mobileL} {
    padding-top: 20px;
  }
`;

export {
  JSSwiper,
  SwiperImage,
  SwiperContent
}
