import styled from "styled-components";

const HeaderWrapper = styled.header`
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: ${({theme}) => `60px ${theme.paddings.width}`};
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${({theme}) => theme.device.tablet} {
    padding: 20px;
  }
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  transform: translate(-50%, 0);
  cursor: pointer;
  margin-left: auto;
  margin-right: -250px;

  @media ${({theme}) => theme.device.tablet} {
    display: none;
  }
`;

const MenuItem = styled.div`
  padding: 8px 20px;
  font-size: ${({theme}) => theme.fontSizes.xxsPx};
  text-align: center;
  position: relative;
  color: ${({
              theme,
              color,
              active
            }) => active ? theme.colors.lightBrown : color ? theme.colors[color] : theme.colors.black};
  cursor: pointer;

  &:hover {
    transition: color 0.3s ease-out 10ms;
    color: ${({theme}) => theme.colors.lightBrown3};
  }

  &:after {
    display: ${({active}) => active ? 'block' : 'none'};
    position: absolute;
    content: '';
    width: calc(100% - 40px);
    height: 2px;
    background-color: ${({theme}) => theme.colors.lightBrown};
    left: 20px;
    bottom: 0;
  }

  @media ${({theme}) => theme.device.tablet} {
    font-size: ${({theme}) => theme.fontSizes.smPx};
    color: ${({
                theme,
                color,
                active
              }) => active ? theme.colors.lightBrown : color ? theme.colors[color] : theme.colors.white};
    padding: 15px 0;
    width: 100%;
    display: flex;
    align-items: center;

    &:after {
      display: block;
      background-color: ${({theme, active}) => active ? theme.colors.lightBrown : theme.colors.white};
      width: 100%;
      left: 0;
      bottom: 10px;
    }

    .MuiSvgIcon-root {
      font-size: 30px;
      margin-left: auto;
    }
  }
`

const Logo = styled.img`
  width: 60px;
  height: 55px;
  cursor: pointer;

  @media ${({theme}) => theme.device.tablet} {
    width: 40px;
    height: 37px;
    margin-bottom: ${({mobileMenu}) => mobileMenu ? 40 : 0}px;
  }
`;

const MobileMenuButton = styled.div`
  display: none;
  z-index: 10000;
  position: ${({fixed}) => fixed ? 'fixed' : 'static'};
  top: 26px;
  right: 40px;

  .MuiButtonBase-root {
    padding: 0;
    margin-left: 40px;
  }

  .MuiSvgIcon-root {
    color: ${({theme, color}) => color === 'white' ? theme.colors.white : theme.colors.black};
    font-size: 30px;
  }

  @media ${({theme}) => theme.device.tablet} {
    display: block;
    opacity: ${({active}) => active ? 1 : 0};
  }
`;

const MobileCloseButton = styled.div`
  display: none;
  z-index: 10000;
  position: fixed;
  top: 15px;
  right: 15px;

  .MuiSvgIcon-root {
    color: ${({theme}) => theme.colors.white};
    font-size: 30px;
  }

  @media ${({theme}) => theme.device.tablet} {
    display: ${({active}) => active ? 'block' : 'none'};
  }
`;

const MobileMenuWrap = styled.div`
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;

  @media ${({theme}) => theme.device.tablet} {
    display: ${({show}) => show ? 'block' : 'none'};
  }
`;

const MobileMenu = styled.div`
  background-color: ${({theme}) => theme.colors.black};
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MobileMenuList = styled.div`
  width: calc(100% - 40px);
  padding: 20px;
`;

export default HeaderWrapper;

export {
  Logo,
  Menu,
  MenuItem,
  MobileMenuButton,
  MobileCloseButton,
  MobileMenuWrap,
  MobileMenu,
  MobileMenuList,
}
