import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Fade} from 'react-reveal';
import {
  PageTitle,
  Section,
  SectionContainer,
  SectionContent,
  SectionImage,
  SectionImageCard,
  SectionTitle,
} from "../assets/components/section";
import Button from "../components/Button";
import {deviceSizes, SHOP_LINK} from "../constants/constant";
import {useMediaQuery} from "react-responsive";

const YangbongImage = () => {
  return (
    <SectionImageCard backgroundUrl="/images/about-us/4-1.png">
      <div>養(기를 양) 蜂(벌 봉)</div>
    </SectionImageCard>
  )
}

function AboutUs() {
  const isMobile = useMediaQuery({query: `(max-width: ${deviceSizes.mobileL})`})
  return (
    <>
      <SectionContainer noCover={true} paddingTop={20}>
        <Section mobileNoPaddingY>
          <Grid spacing={{xs: 0, sm: 0, md: 10}} container>
            <Grid md={5}>
              <PageTitle marginBottom={20} mobileMarginBottom={20}>
                養(기를 양) 蜂(벌 봉)
              </PageTitle>
              <PageTitle mobileMarginBottom={20}>
                사람과 자연 모두를{isMobile ? "" : <br/>} 이롭게 하는 양봉
              </PageTitle>
              {isMobile ? <SectionImage minHeight={false} src="/images/cover/about-us.png" mobileHeight={150}/> : ""}
              <SectionContent bottom={false} marginTop={20}>
                인류가 발견한 식품 중 꿀벌이 만든 것을 능가하는 자연식품은 없습니다. 꿀은 꿀벌을 통해 자연이 인간에게 주는 최고의 선물이지요.
              </SectionContent>
            </Grid>
            <Grid md={7}>
              {isMobile ? "" : <SectionImage src="/images/cover/about-us.png" minHeight={true}/>}
            </Grid>
          </Grid>
        </Section>
        <Section paddingTop={100} mobilePaddingTop={60} mobilePaddingBottom={60}>
          <Grid spacing={{xs: 0, sm: 0, md: 10}} container>
            <Grid md={5}>
              <SectionImage minHeight="150" src="/images/about-us/1.png"/>
            </Grid>
            <Grid md={7}>
              <SectionTitle mobileMarginTop={40}>
                <Fade>
                  자연과 공생하는 조선양봉
                </Fade>
              </SectionTitle>
              <SectionContent bottom={true}>
                1980년 아버지로부터 이어진 벌꿀과의 삶. 인류가 발견한 식품 중 꿀벌이 만든 것을 능가하는 자연식품은 없습니다. 꿀은 꿀벌을 통해 자연이 인간에게 주는 최고의 선물이지요. 아버지로부터
                배운 철학과 지혜가 깃든 전통방식만을 고집하며 1년에 딱 한 번, 하늘이 허락하는 만큼의 자연 숙성 완숙 꿀만을 정성스럽게 담습니다.
              </SectionContent>
            </Grid>
          </Grid>
        </Section>
        <Section paddingTop={100} mobileNoPaddingY>
          <Grid spacing={{xs: 0, sm: 0, md: 10}} container>
            <Grid xs={12} sm={12} md={6}>
              <SectionTitle>
                <Fade>
                  살아 숨 쉬는 땅, 청정 지역 여주
                </Fade>
              </SectionTitle>
              <SectionContent marginTop={20}>
                예부터 임금님의 수라상에 오르는 쌀을 재배했을 만큼 비옥한 토지와 맑은 남한강을 끼고 있는 청정 여주.<br/>
                살아 숨 쉬는 땅에는 갖가지 야생화와 아카시아 그리고 특수 밀원인 야생 밤 나무, 옻나무 등이 풍부하게 자리하고 있습니다.<br/>
                <br/>
                조선양봉의 모든 꿀은 청정 지역 여주에서 고정양봉으로 이루어집니다.<br/>
                <br/>
                생산량을 늘리기 위해 개화 시기에 맞춰 벌통을 싣고 다니는 단순 채밀과 농축을 기계적으로 반복하는 일은 이동 양봉은 벌을 혹사하며 자연 숙성을 위한 기다림의 시간을 갖지 못하기
                때문입니다.<br/><br/>
                {isMobile ? (<><YangbongImage/><br/></>) : ""}
                사람과 자연 모두를 이롭게 하는 양봉.<br/>
                <br/>
                그것이 우리가 걸어온 길이고 조선양봉이 지속해서 나아가고자 하는 길입니다.
              </SectionContent>
              <Button onClick={() => window.open(SHOP_LINK, '_blank')} style={{marginTop: 60, marginBottom: 40}}>
                제품 구매하기
              </Button>
            </Grid>
            <Grid xs={12} sm={12} md={6}>
              {isMobile ? "" : <YangbongImage/>}
            </Grid>
          </Grid>
        </Section>
      </SectionContainer>
    </>
  )
}

export default AboutUs;
