import styled from "styled-components";

const PrivacyContainer = styled.div`
  padding: ${20 / 16}rem ${270 / 16}rem ${100 / 16}rem ${270 / 16}rem;
  @media ${({theme}) => theme.device.tablet} {
    padding: 0;
  }
`;

const PageTitle = styled.h1`
  font-weight: 700;
  font-size: ${({theme}) => theme.fontSizes.xlPx};
  line-height: ${({theme}) => theme.lineHeights.xlPx};
  margin-bottom: 20px;
`;

const PageContent = styled.div`
  font-weight: 400;
  font-size: ${({theme}) => theme.fontSizes.xxsPx};
  @media ${({theme}) => theme.device.tablet} {
    font-size: ${({theme}) => theme.fontSizes.xxsPx};
    margin-bottom: 60px;
  }
`;

export {
  PrivacyContainer,
  PageTitle,
  PageContent
}
