import React from "react";
import {Section, SectionContainer} from "../assets/components/section";
import Grid from "@mui/material/Unstable_Grid2";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {NewsContent, NewsCover, NewsSubtitle, NewsSubtitle2, NewsTitle} from "../assets/routes/news";

function NewsPage4() {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <>
      <NewsCover backgroundUrl='/images/news/4-1.png'>
      </NewsCover>
      <SectionContainer noCover={false}>
        <Section>
          <Grid spacing={{xs: 12, sm: 12}} container>
            <Grid md={12}>
              <NewsSubtitle>
                News & Events
              </NewsSubtitle>
              <NewsSubtitle2>
                MAY
              </NewsSubtitle2>
              <NewsTitle mobileMarginTop={10} marginTop={20}>
                핫플레이스! 신사 SUM'S에서 조선양봉 꿀 하이볼을 경험해보세요.
              </NewsTitle>
              <NewsContent mobileMarginTop={10} marginTop={40}>
                SUM’S x Josun Yangbong<br/>
                <br/>
                신사에 위치한 숨스(SUM'S) 는 각종 국제대회 수상과 13년 경력의 정통 이탈리안 쉐프가 운영하는 퓨전 다이닝바입니다.<br/>
                <br/>
                숨스에서는 전시되어 있는 조선양봉의 제품과 조선양봉의 야생화꿀을 이용한 ‘조선양봉 꿀 하이볼’을 즐기실 수 있습니다.<br/>
                <br/>
                편안하고 즐거운 최고의 공간 숨스.<br/>
                <br/>
                이탈리안 오너 쉐프가 만드는 최고의 요리와 함께 최고의 꿀로 만든 달콤한 조선양봉 꿀 하이볼을 경험해보세요.<br/>
              </NewsContent>
            </Grid>
          </Grid>
        </Section>
      </SectionContainer>
    </>
  )
}

export default NewsPage4;
