import styled from "styled-components";
import {Checkbox, FormControlLabel, TextField} from "@mui/material";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Inter, serif;
`;

const InputLabel = styled.p`
  font-weight: 700;
  font-size: ${({theme}) => theme.fontSizes.smPx};
  line-height: ${({theme}) => theme.lineHeights.smPx};
  margin-top: 20px;
  margin-bottom: 12px;
  @media ${({theme}) => theme.device.tablet} {
    font-size: ${({theme}) => theme.fontSizes.xsPx};
    line-height: ${({theme}) => theme.lineHeights.xsPx};
  }
`;

const JYTextField = styled(TextField)`
  & label.Mui-focused {

  }

  & .MuiInput-underline:after {

  }

  & .MuiOutlinedInput-root {
    padding: 0;
    border-radius: 5px;

    & input, textarea {
      padding: 16px 12px;
      font-size: ${({theme}) => theme.fontSizes.xxsPx};

      @media ${({theme}) => theme.device.tablet} {
        padding: 16px 12px;
        font-size: ${({theme}) => theme.fontSizes.xxxsPx};
      }
    }

    & fieldset {
    }

    &:hover fieldset {
    }

    &.Mui-focused fieldset {
    }
  }
`;

const Required = styled.span`
  color: ${({theme}) => theme.colors.red};
`;

const JSFormControlLabel = styled(FormControlLabel)`
  padding: 40px 10px;
  margin: 0;

  & .MuiFormControlLabel-label {
    padding: 20px;
    font-weight: 400;
    font-size: ${({theme}) => theme.fontSizes.xxsPx};
    line-height: ${({theme}) => theme.fontSizes.xxsPx};
    text-align: center;

    @media ${({theme}) => theme.device.tablet} {
      padding: 0 10px;
      font-size: ${({theme}) => theme.fontSizes.xxxsPx};
      line-height: ${({theme}) => theme.fontSizes.xxxsPx};
    }
  }

  & .MuiFormControlLabel-asterisk {
    display: none;
  }
`;

const JSCheckbox = styled(Checkbox)`
  overflow: hidden;
  color: ${({theme}) => theme.colors.black2};
  padding: 0;
  margin-bottom: 2px;

  &.Mui-checked {
    color: ${({theme}) => theme.colors.lightBrown};
  }

  & .MuiSvgIcon-root {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: none;
  }
`;


export {
  Form,
  InputLabel,
  JYTextField,
  Required,
  JSCheckbox,
  JSFormControlLabel
}
