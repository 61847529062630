import React, {useState} from "react";
import HeaderWrapper, {
  Logo,
  Menu,
  MenuItem,
  MobileCloseButton,
  MobileMenu,
  MobileMenuButton,
  MobileMenuList,
  MobileMenuWrap
} from "../../assets/components/layouts/header";
import {useLocation, useNavigate} from "react-router-dom";
import {IconButton} from "@mui/material";
import {ChevronRight, Close, Menu as MenuIcon} from "@mui/icons-material";
import {Slide} from 'react-reveal';

function Header(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const color = location.pathname === '/' || location.pathname === '/story' || location.pathname.startsWith("/news/") ? "white" : null;
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [hideMobileMenu, setHideMobileMenu] = useState(true);
  const [loading, setLoading] = useState(false);

  const MENU = [
    {name: 'about-us', title: '회사소개', path: '/about-us'},
    {name: 'story', title: '자연숙성', path: '/story'},
    {name: 'contact', title: '고객센터', path: '/contact'},
    {name: 'news', title: 'News & Events', path: '/news'},
  ]

  const goTo = (path) => {
    navigate(path);
    setOpenMobileMenu(false);

    setLoading(true);
    setTimeout(() => {
      setHideMobileMenu(true);
      setLoading(false);
    }, 1000);
  }

  const toggleMobileMenu = (hide) => {
    if (loading) return;
    setOpenMobileMenu((prev) => !prev);

    if (hide === true) {
      setLoading(true);
      setTimeout(() => {
        setHideMobileMenu(true);
        setLoading(false);
      }, 1000);
    } else {
      setHideMobileMenu(false);
    }
  }

  return (
    <HeaderWrapper>
      <Logo onClick={() => navigate('/')}
            src={color === 'white' ? "/images/logo/logo-white.png" : "/images/logo/logo.png"}/>
      <Menu>
        {
          MENU.map((item, index) => (
            <MenuItem
              onClick={() => goTo(item.path)}
              color={color}
              active={location.pathname === item.path}
            >
              {item.title}
            </MenuItem>
          ))
        }
      </Menu>
      <MobileMenuButton color={color} active={!openMobileMenu} onClick={() => toggleMobileMenu(false)}>
        <IconButton aria-label="menu" size="large">
          {
            openMobileMenu ? <Close/> : <MenuIcon/>
          }
        </IconButton>
      </MobileMenuButton>
      <MobileCloseButton color={color} active={openMobileMenu} onClick={() => toggleMobileMenu(true)}>
        <IconButton aria-label="menu" size="large">
          <Close/>
        </IconButton>
      </MobileCloseButton>
      <MobileMenuWrap show={!hideMobileMenu}>
        <Slide duration={1000} onAnimationEnd={() => setHideMobileMenu(true)} when={openMobileMenu}
               style={{height: '100%'}} right>
          <MobileMenu>
            <MobileMenuList>
              <Logo onClick={() => goTo('/')} src="/images/logo/logo-white.png" mobileMenu/>
              {
                MENU.map((item, index) => (
                  <MenuItem
                    onClick={() => goTo(item.path)}
                    color={color}
                    active={location.pathname === item.path}
                  >
                    {item.title} <ChevronRight/>
                  </MenuItem>
                ))
              }
            </MobileMenuList>
          </MobileMenu>
        </Slide>
      </MobileMenuWrap>
    </HeaderWrapper>
  );
}

export default Header;
