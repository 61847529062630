import styled from "styled-components";
import {Card, CardActions, CardContent} from "@mui/material";
import Button from "@mui/material/Button";

const StyledCard = styled(Card)`
  border-radius: 0;
  box-shadow: none;
`;

const CardTitle = styled.div`
  color: ${({theme}) => theme.colors.black};
  font-weight: 700;
  font-size: 14px;

  @media ${({theme}) => theme.device.tabletL} {
    font-size: ${({theme}) => theme.fontSizes.sm};
  }
`;

const CardText = styled.div`
  color: ${({theme}) => theme.colors.black};
  font-weight: 400;
  font-size: 14px;
  padding-top: 5px;

  @media ${({theme}) => theme.device.tabletL} {
    font-size: ${({theme}) => theme.fontSizes.sm};
  }
`;

const CardDate = styled.div`
  color: ${({theme}) => theme.colors.black};
  font-weight: 700;
  font-size: ${({theme}) => theme.fontSizes.xxxxxsPx};

  @media ${({theme}) => theme.device.tabletL} {
  }
`;

const StyledCardActions = styled(CardActions)`
  padding: 0 20px 15px;
`;

const CardButton = styled(Button)`
  color: ${({theme}) => theme.colors.orange};
  font-weight: 700;
  font-size: ${({theme}) => theme.fontSizes.xxxxxsPx};
  position: relative;
  float: right;
  margin-left: auto;
`;

const StyledCardContent = styled(CardContent)`
  font-family: 'MaruBuri-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  min-height: 70px;
`;

export default StyledCard

export {
  CardTitle,
  CardText,
  CardDate,
  StyledCardActions,
  CardButton,
  StyledCardContent
}
