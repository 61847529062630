import React from 'react';
import theme from "./assets/theme";
import {ThemeProvider} from "styled-components";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {StyledEngineProvider} from "@mui/material/styles";
// Components
import Header from "./components/layouts/Header";
import Footer from "./components/layouts/Footer";

// Routes
import Index from "./routes/Index";
import AboutUs from "./routes/AboutUs";
import Story from "./routes/Story";
import Contact from "./routes/Contact";
import News from "./routes/News";
import Privacy from "./routes/Privacy";
import NewsPage1 from "./routes/NewsPage1";
import NewsPage2 from "./routes/NewsPage2";
import NewsPage3 from "./routes/NewsPage3";
import NewsPage4 from "./routes/NewsPage4";
import NewsPage5 from "./routes/NewsPage5";
import NewsPage6 from "./routes/NewsPage6";
import NewsPage7 from "./routes/NewsPage7";
import ScrollToTop from "./share/ScrollToTop";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <ScrollToTop>
            <Header/>
            <Routes>
              <>
                <Route exact path={"/"} element={<Index/>}/>
                <Route exact path={"/about-us"} element={<AboutUs/>}/>
                <Route exact path={"/story"} element={<Story/>}/>
                <Route exact path={"/contact"} element={<Contact/>}/>
                <Route exact path={"/news"} element={<News/>}/>
                <Route exact path={"/privacy"} element={<Privacy/>}/>
                <Route exact path={"/news/1"} element={<NewsPage1/>}/>
                <Route exact path={"/news/2"} element={<NewsPage2/>}/>
                <Route exact path={"/news/3"} element={<NewsPage3/>}/>
                <Route exact path={"/news/4"} element={<NewsPage4/>}/>
                <Route exact path={"/news/5"} element={<NewsPage5/>}/>
                <Route exact path={"/news/6"} element={<NewsPage6/>}/>
                <Route exact path={"/news/7"} element={<NewsPage7/>}/>
              </>
            </Routes>
            <Footer/>
          </ScrollToTop>
        </ThemeProvider>
      </BrowserRouter>
    </StyledEngineProvider>
  );
}

export default App;
