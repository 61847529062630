import React from "react";
import StyledButton from "../assets/components/button";

function Button (props) {
  const { children } = props;
  return (
    <StyledButton variant="contained" {...props}>
      {children}
    </StyledButton>
  )
}

export default Button;
