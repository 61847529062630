import styled from "styled-components";

const CoverBackground = styled.div`
  width: 100vw;
  height: 600px;
  position: absolute;
  z-index: -1;
  background-color: ${({theme}) => theme.colors.black};
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${({theme}) => theme.device.tablet} {
    height: ${({mobileHeight}) => mobileHeight ? `${mobileHeight}px` : "640px"};
  }
`;

const BackgroundLogo = styled.img`
  width: 480px;
  height: 444px;
  opacity: 0.2;

  @media ${({theme}) => theme.device.tablet} {
    width: 240px;
    height: 222px;
  }
`;

export {
  CoverBackground,
  BackgroundLogo,
}
