const SHOP_LINK = 'https://smartstore.naver.com/josunyangbong/products/7912255141';

const emailJS = {
  serviceID: 'service_xzsoqyw',
  templateID: 'template_rxcrdfb',
  publicKey: 'e_pxJPzUMNs3zSjz2'
}

const deviceSizes = {
  mobile: "600px",
  mobileL: "768px",
  tablet: "992px",
  tabletL: "1200px",
};

export {
  SHOP_LINK,
  emailJS,
  deviceSizes
}
