import styled from "styled-components";

const SectionContainer = styled.div`
  padding-top: ${({
                    theme,
                    noPadding,
                    paddingTop
                  }) => paddingTop ? `${paddingTop}px` : noPadding ? 0 : theme.layout.sectionPaddingY};
  padding-bottom: ${({
                       theme,
                       noPadding,
                       paddingBottom
                     }) => paddingBottom ? `${paddingBottom}px` : noPadding ? 0 : theme.layout.sectionPaddingY};
  padding-right: ${({theme, noPadding}) => noPadding ? 0 : theme.paddings.width};
  padding-left: ${({theme, noPadding}) => noPadding ? 0 : theme.paddings.width};
  margin-top: ${({noCover, theme}) => noCover ? theme.layout.headerHeight : 0}px;
  margin-right: ${({marginRight}) => marginRight ? `${marginRight}px` : 0};
  background-color: ${({backgroundColor}) => backgroundColor};
  background-image: url(${process.env.PUBLIC_URL}${({backgroundUrl}) => backgroundUrl});
  background-size: cover;
  background-position: center;

  @media ${({theme}) => theme.device.tablet} {
    margin-top: ${({noCover, theme}) => noCover ? 40 : 0}px;
    padding: 60px 20px 0 20px;
    margin-right: 0;
  }
`;

const Section = styled.div`
  padding-bottom: ${({paddingBottom, noPadding}) => noPadding ? 0 : paddingBottom ? `${paddingBottom}px` : 0};
  padding-top: ${({paddingTop}) => paddingTop ? `${paddingTop}px` : 0};
  background-color: ${({backgroundColor}) => backgroundColor};
  padding-left: ${({paddingX, theme}) => paddingX ? theme.layout.paddingX : 0};
  padding-right: ${({paddingX, theme}) => paddingX ? theme.layout.paddingX : 0};
  background-image: url(${process.env.PUBLIC_URL}${({backgroundUrl}) => backgroundUrl});
  background-size: cover;
  background-position: center;
  height: ${({height}) => height ? `${height}px` : 'auto'};
  align-items: center;
  display: ${({center}) => center ? 'flex' : 'block'};
  justify-content: center;
  align-items: center;

  @media ${({theme}) => theme.device.tablet} {
    padding-bottom: ${({
                         mobilePaddingBottom,
                         noPadding
                       }) => noPadding ? 0 : mobilePaddingBottom ? `${mobilePaddingBottom}px` : 120}px;
    padding-left: ${({paddingX, theme}) => paddingX ? theme.layout.tabletPaddingX : 0};
    padding-right: ${({paddingX, theme}) => paddingX ? theme.layout.tabletPaddingX : 0};
  }

  @media ${({theme}) => theme.device.mobileL} {
    padding-bottom: ${({
                         mobilePaddingBottom,
                         mobileNoPaddingY
                       }) => mobileNoPaddingY ? 0 : mobilePaddingBottom ? `${mobilePaddingBottom}px` : 60};
    padding-top: ${({
                      mobilePaddingTop,
                      mobileNoPaddingY
                    }) => mobileNoPaddingY ? 0 : mobilePaddingTop ? `${mobilePaddingTop}px` : 0};
  }
`;

const PageTitle = styled.p`
  font-weight: 700;
  font-size: ${({theme}) => theme.fontSizes.xlPx};
  line-height: ${({theme}) => theme.lineHeights.xlPx};
  color: ${({theme, color}) => color ? color : theme.colors.black2};
  margin-bottom: ${({marginBottom}) => marginBottom ? `${marginBottom}px` : 0};

  @media ${({theme}) => theme.device.tablet} {
    font-size: ${({theme}) => theme.fontSizes.xlPx};
    text-align: ${({mobileCenter}) => mobileCenter ? "center" : "left"};
    margin-top: ${({mobileMarginTop}) => mobileMarginTop ? `${mobileMarginTop}px` : 0};
    margin-bottom: ${({mobileMarginBottom}) => mobileMarginBottom ? `${mobileMarginBottom}px` : 0};
  }
`;

const SectionTitle = styled.p`
  font-weight: 700;
  font-size: ${({theme}) => theme.fontSizes.lgPx};
  line-height: ${({theme}) => theme.lineHeights.lgPx};
  color: ${({theme, color}) => color ? color : theme.colors.black2};

  @media ${({theme}) => theme.device.tablet} {
    font-size: ${({theme}) => theme.fontSizes.xsPx};
    text-align: ${({mobileCenter}) => mobileCenter ? "center" : "left"};
    margin-top: ${({mobileMarginTop}) => mobileMarginTop ? `${mobileMarginTop}px` : 0};
  }
`;

const SectionSubTitle = styled.p`
  font-weight: 400;
  font-size: ${({theme}) => theme.fontSizes.smPx};
  margin-bottom: ${({theme, marginBottom}) => marginBottom ? `${marginBottom}px` : theme.margins.sectionSubTitleBottom};
  margin-top: ${({bottom, marginTop}) => bottom ? 'auto' : marginTop ? `${marginTop}px` : 0};
  color: ${({color, theme}) => color ? color : theme.colors.black2};

  > b {
    color: ${({theme}) => theme.colors.orange};
  }

  @media ${({theme}) => theme.device.tablet} {
    margin-top: ${({mobileMarginTop}) => mobileMarginTop ? `${mobileMarginTop}px` : 0};
    margin-bottom: ${({mobileMarginBottom}) => mobileMarginBottom ? `${mobileMarginBottom}px` : "20px"};
    text-align: ${({mobileCenter}) => mobileCenter ? "center" : "left"};
    font-size: ${({theme}) => theme.fontSizes.xsPx};
  }

  @media ${({theme}) => theme.device.mobileL} {
    font-size: ${({theme}) => theme.fontSizes.xsPx};
  }
`;

const SectionSubTitleHighLight = styled.p`
  font-weight: 700;
  font-size: ${({theme}) => theme.fontSizes.sm};
  margin-top: 20px;
  margin-bottom: 20px;
  color: ${({color, theme}) => color ? color : theme.colors.black2};

  > b {
    color: ${({theme}) => theme.colors.orange};
  }

  @media ${({theme}) => theme.device.tablet} {
    text-align: ${({mobileCenter}) => mobileCenter ? "center" : "left"};
  }

  @media ${({theme}) => theme.device.mobileL} {
    font-size: ${({theme}) => theme.fontSizes.sm};
  }
`;

const SectionContent = styled.p`
  font-weight: 400;
  font-size: ${({theme}) => theme.fontSizes.smPx};
  line-height: ${({theme}) => theme.lineHeights.smPx};
  color: ${({theme, color}) => color ? color : theme.colors.black2};
  padding-top: ${({bottom}) => bottom ? '20px' : '0'};
  margin-top: ${({bottom, marginTop}) => bottom ? 'auto' : marginTop ? `${marginTop}px` : '0'};

  > b {
    color: ${({theme}) => theme.colors.orange};
  }

  @media ${({theme}) => theme.device.tablet} {
    font-size: ${({theme}) => theme.fontSizes.xsPx};
    line-height: ${({theme}) => theme.lineHeights.xsPx};
  }
`;

const SectionImageContent = styled.p`
  font-weight: 400;
  font-size: ${({theme}) => theme.fontSizes.xsPx};
  margin-bottom: ${({theme, marginBottom}) => marginBottom ? `${marginBottom}px` : theme.margins.sectionSubTitleBottom};
  margin-top: ${({bottom, marginTop}) => bottom ? 'auto' : marginTop ? `${marginTop}px` : 0};
  color: ${({color, theme}) => color ? color : theme.colors.black2};

  @media ${({theme}) => theme.device.tablet} {
    margin-top: ${({mobileMarginTop}) => mobileMarginTop ? `${mobileMarginTop}px` : 0};
    margin-bottom: ${({mobileMarginBottom}) => mobileMarginBottom ? `${mobileMarginBottom}px` : "20px"};
    text-align: ${({mobileCenter}) => mobileCenter ? "center" : "left"};
  }

  @media ${({theme}) => theme.device.mobileL} {
    font-size: ${({theme}) => theme.fontSizes.xxsPx};
  }
`;

const SectionImage = styled.img`
  width: ${({width}) => width ? width : `${100}%`};
  height: ${({height, minHeight}) => minHeight ? '100%' : `${height}px`};
  min-height: ${({minHeight}) => minHeight ? minHeight : 'auto'};
  object-fit: cover;

  @media ${({theme}) => theme.device.tablet} {
    height: ${({
                 height,
                 minHeight,
                 mobileHeight
               }) => minHeight ? '100%' : mobileHeight ? mobileHeight : `${height / 2}px`};
    width: 100%;
  }
`;

const SectionImageCard = styled.div`
  width: ${({width}) => width ? `{$width}px` : `${100}%`};
  height: ${({height}) => height ? `${height}px` : '100%'};
  background-image: url(${process.env.PUBLIC_URL}${({backgroundUrl}) => backgroundUrl});
  background-size: cover;
  background-position: center;
  text-align: left;
  position: relative;

  div {
    position: absolute;
    bottom: 24px;
    left: 24px;
    color: white;
    font-size: ${({theme}) => theme.fontSizes.xs};
    line-height: normal;
  }

  @media ${({theme}) => theme.device.tablet} {
    min-width: 350px;
    min-height: 500px;
  }
`;

const SectionText = styled.p`
  font-weight: 400;
  font-size: ${({theme}) => theme.fontSizes.sectionText};
  line-height: ${({theme}) => theme.lineHeights.lg};
  color: ${({theme, color}) => color ? color : theme.colors.black2};
`;

const FlexContainerY = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
`;

const Logo = styled.img`
  width: 314px;
  height: 36px;
  cursor: pointer;
  margin-top: 40px;

  @media ${({theme}) => theme.device.tablet} {
    width: 192px;
    height: 22px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
  }
`;

export {
  SectionContainer,
  Section,
  PageTitle,
  SectionTitle,
  SectionSubTitle,
  SectionSubTitleHighLight,
  SectionContent,
  SectionImageContent,
  SectionImage,
  SectionImageCard,
  SectionText,
  FlexContainerY,
  Logo
}
