const calcRem = (size) => `${size / 16}rem`;

const colors = {
  white: '#ffffff',
  whiteText: '#fff8ec',
  lightBrown: '#b7a398',
  lightBrown2: '#eebca0',
  lightBrown3: '#a76C4b',
  brown: '#31251c',
  black: '#000000',
  black2: '#0b0c0e',
  orange: '#c95616',
  yellow: '#ff9900',
  gray: '#e8ebef',
  gray2: '#c8d0d8',
  gray3: '#a9afb7',
  gray4: '#5a6068',
  gray5: '#cbd0d8',
  red: '#ff003d',
};

const layout = {
  width: "1040px",
  paddingX: calcRem(200),
  sectionPaddingY: calcRem(128),
  headerHeight: 195,
  coverHeight: calcRem(700),
  tabletPaddingX: calcRem(40),
  mobileSectionPaddingY: calcRem(60)
}

const paddings = {
  width: "calc(50vw - 530px)",
  footerWidth: "calc(50vw - 630px)"
}

const deviceSizes = {
  mobile: "600px",
  mobileL: "768px",
  tablet: "992px",
  tabletL: "1200px",
};

const device = {
  mobile: `only screen and (max-width: ${deviceSizes.mobile})`,
  mobileL: `only screen and (max-width: ${deviceSizes.mobileL})`,
  tablet: `only screen and (max-width: ${deviceSizes.tablet})`,
  tabletL: `only screen and (max-width: ${deviceSizes.tabletL})`,
};

const fontSizes = {
  xs: calcRem(10),
  sm: calcRem(16),
  base: calcRem(20),
  md: calcRem(24),
  lg: calcRem(32),
  xl: calcRem(40),
  xxl: calcRem(44),
  sectionText: calcRem(30),
  xxlPx: "30px",
  xlPx: "26px",
  lgPx: "24px",
  mdPx: "22px",
  basePx: "20px",
  smPx: "18px",
  xsPx: "16px",
  xxsPx: "14px",
  xxxsPx: "12px",
  xxxxsPx: "11px",
  xxxxxsPx: "10px",
};

const margins = {
  coverBottom: calcRem(120),
  sectionBottom: calcRem(160),
  sectionSubTitleBottom: calcRem(60),
}

const lineHeights = {
  small: calcRem(25),
  base: calcRem(35),
  lg: calcRem(45),
  xl: calcRem(55),
  xxl: calcRem(65),
}

const button = {
  height: calcRem(60),
  paddingX: calcRem(40),
  mobilePaddingX: calcRem(20),
}

const theme = {
  layout,
  paddings,
  colors,
  deviceSizes,
  device,
  fontSizes,
  margins,
  lineHeights,
  button
};

export default theme;
