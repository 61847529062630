import styled from "styled-components";

const NewsCover = styled.div`
  height: 400px;
  background-color: ${({theme}) => theme.colors.black};
  background-image: url(${process.env.PUBLIC_URL}${({backgroundUrl}) => backgroundUrl});
  background-size: cover;
  background-position: center;
  position: relative;
  @media ${({theme}) => theme.device.tablet} {
    height: 200px;
  }
`;

const NewsSubtitle = styled.span`
  font-weight: 700;
  font-size: ${({theme}) => theme.fontSizes.xxsPx};
  color: ${({theme, color}) => color ? color : theme.colors.lightBrown};

  @media ${({theme}) => theme.device.tablet} {
    font-size: ${({theme}) => theme.fontSizes.xxxsPx};
    text-align: ${({mobileCenter}) => mobileCenter ? "center" : "left"};
    margin-top: ${({mobileMarginTop}) => mobileMarginTop ? `${mobileMarginTop}px` : 0};
  }
`;

const NewsSubtitle2 = styled.span`
  font-weight: 700;
  font-size: ${({theme}) => theme.fontSizes.xxsPx};
  color: ${({theme, color}) => color ? color : theme.colors.lightBrown};
  float: right;

  @media ${({theme}) => theme.device.tablet} {
    font-size: ${({theme}) => theme.fontSizes.xxxsPx};
    text-align: ${({mobileCenter}) => mobileCenter ? "center" : "left"};
    margin-top: ${({mobileMarginTop}) => mobileMarginTop ? `${mobileMarginTop}px` : 0};
  }
`;

const NewsTitle = styled.p`
  font-weight: 700;
  font-size: ${({theme}) => theme.fontSizes.xxlPx};
  color: ${({theme, color}) => color ? color : theme.colors.black2};
  margin-top: ${({marginTop}) => marginTop ? `${marginTop}px` : 0};
  margin-bottom: ${({marginBottom}) => marginBottom ? `${marginBottom}px` : 0};

  @media ${({theme}) => theme.device.tablet} {
    font-size: ${({theme}) => theme.fontSizes.mdPx};
    text-align: ${({mobileCenter}) => mobileCenter ? "center" : "left"};
    margin-top: ${({mobileMarginTop}) => mobileMarginTop ? `${mobileMarginTop}px` : 0};
    margin-bottom: ${({mobileMarginBottom}) => mobileMarginBottom ? `${mobileMarginBottom}px` : 0};
  }
`;

const NewsContent = styled.p`
  font-weight: 400;
  font-size: ${({theme}) => theme.fontSizes.basePx};
  color: ${({theme, color}) => color ? color : theme.colors.black2};
  margin-top: ${({marginTop}) => marginTop ? `${marginTop}px` : 0};
  margin-bottom: ${({marginBottom}) => marginBottom ? `${marginBottom}px` : 0};

  @media ${({theme}) => theme.device.tablet} {
    font-size: ${({theme}) => theme.fontSizes.xxsPx};
    text-align: ${({mobileCenter}) => mobileCenter ? "center" : "left"};
    margin-top: ${({mobileMarginTop}) => mobileMarginTop ? `${mobileMarginTop}px` : 0};
    margin-bottom: ${({mobileMarginBottom}) => mobileMarginBottom ? `${mobileMarginBottom}px` : 0};
  }
`;

export {
  NewsCover,
  NewsSubtitle,
  NewsSubtitle2,
  NewsTitle,
  NewsContent
}
