import styled from "styled-components";

const FooterWrapper = styled.footer`
  padding: ${({theme}) => `80px ${theme.paddings.footerWidth}`};
  font-family: "Nanum Gothic", sans-serif;
  background-color: ${({theme}) => theme.colors.black};

  @media ${({theme}) => theme.device.tablet} {
    padding: ${({theme}) => `40px ${theme.layout.tabletPaddingX}`};
  }
`;


const Logo = styled.img`
  width: 107px;
  height: 145px;

  @media ${({theme}) => theme.device.tablet} {
    width: 74px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
`;

const FooterContent = styled.div`
  color: ${({theme}) => theme.colors.gray5};
  font-weight: 400;
  font-size: ${({theme}) => theme.fontSizes.xsPx};
  line-height: ${({theme}) => theme.lineHeights.base};

  > b {
    font-weight: 700;
  }

  @media ${({theme}) => theme.device.tabletL} {
    font-size: ${({theme}) => theme.fontSizes.xxxxsPx};
    line-height: ${({theme}) => theme.lineHeights.sm};
  }
`;

const FooterContent2 = styled.div`
  color: ${({theme}) => theme.colors.gray5};
  font-weight: 400;
  font-size: ${({theme}) => theme.fontSizes.xsPx};
  line-height: ${({theme}) => theme.lineHeights.base};

  > b {
    font-weight: 700;
  }

  @media ${({theme}) => theme.device.tabletL} {
    font-size: ${({theme}) => theme.fontSizes.xxxxxsPx};
    line-height: ${({theme}) => theme.lineHeights.base};
  }
`;

export default FooterWrapper;

export {
  Logo,
  FooterContent,
  FooterContent2
};
