import React, {useEffect} from "react";
import Grid from '@mui/material/Unstable_Grid2';
import {SwiperSlide} from "swiper/react";
import {Fade} from 'react-reveal';
import {useMediaQuery} from 'react-responsive'

import {BackgroundLogo, CoverBackground,} from "../assets/routes";
import {
  Logo,
  Section,
  SectionContainer,
  SectionImageCard,
  SectionSubTitle,
  SectionSubTitleHighLight,
  SectionTitle
} from "../assets/components/section";
import Button from "../components/Button";
import {JSSwiper, SwiperImage} from "../assets/components/swiper";
import {deviceSizes, SHOP_LINK} from "../constants/constant";
import {useTheme} from "styled-components";
import {Cover, CoverDescription, CoverTextWrapper, CoverTitle} from "../assets/components/cover";

function Index() {
  const theme = useTheme();
  const isTablet = useMediaQuery({query: `(max-width: ${deviceSizes.tablet})`})
  const isMobile = useMediaQuery({query: `(max-width: ${deviceSizes.mobileL})`})

  useEffect(() => {
    console.log('isTablet', isTablet);
    console.log('isMobile', isMobile);
  }, []);

  const YangbongImage = () => {
    return (
      <SectionImageCard backgroundUrl="/images/landing/6-1.png" height={720} width={480}>
        <div>조선양봉은 충분한 자연 숙성을 위해 꿀의<br/>종류별로 딱 1번의 채밀만을 고집합니다.</div>
      </SectionImageCard>
    )
  }

  return (
    <>
      <CoverBackground>
        <BackgroundLogo src="/images/logo/logo-white.png"/>
      </CoverBackground>
      <Fade duration={2000} cascade bottom big>
        <Cover backgroundUrl="/images/cover/landing.png" isIndex>
          <CoverTextWrapper>
            <Fade duration={1000} cascade left>
              <CoverTitle>
                우리의 <b>정성</b>이<br/>
                당신의 <b>진심</b>으로 바뀌는 순간
              </CoverTitle>
            </Fade>
            <CoverDescription>
              조선양봉의 모든 꿀은 자연 그대로의 방식으로<br/>
              자연숙성만을 고집합니다.
            </CoverDescription>
            <Button onClick={() => window.open(SHOP_LINK, '_blank')}>제품 구매하기</Button>
          </CoverTextWrapper>
        </Cover>
      </Fade>
      <SectionContainer paddingY={60}>
        <Section noPadding={true}>
          <Grid spacing={{xs: 4, sm: 4}} container>
            <Grid xs={12} sm={12} md={5}>
              <SectionTitle>
                <Fade left>
                  자연과 공생하는 조선양봉
                </Fade>
              </SectionTitle>
            </Grid>
            <Grid xs={12} sm={12} md={7}>
              <SectionSubTitle marginBottom={20}>
                가장 부지런하고 유능한 농부는 바로 꿀벌입니다. 그 귀중한 가치를 너무도 잘 알기에 모두가 꿀에 집중할 때 우리는 <b>벌에 집중하여</b> 자연의 이치를 지키고자 합니다. <br/>
              </SectionSubTitle>
              <SectionSubTitleHighLight>“공존을 위해 작게 존재한다”</SectionSubTitleHighLight>
              <SectionSubTitle marginBottom={100}>
                벌을 혹사시키며 꿀의 영양소를 파괴시키는 대량생산, 인공 농축, 사양꿀 생산이 아닙니다. 벌의 날갯짓과 소화를 거쳐 기다림 끝에 얻게 되는 자연숙성 완숙 꿀만을 정성스럽게 담습니다.
                자연이 준 본연의 풍부한 영양성분과 품격 있는 달콤함, 그리고 잊을 수 없는 맛과 향.
              </SectionSubTitle>
            </Grid>
          </Grid>
          <JSSwiper
            marginBottom={-220}
            mobileMarginBottom={-120}
            slidesPerView={isMobile ? 1 : isTablet ? 2 : 3}
            spaceBetween={isMobile ? 10 : isTablet ? 20 : 30}
            slidesOffsetBefore={isMobile ? 10 : 0}
            slidesOffsetAfter={isMobile ? 10 : 0}
          >
            <SwiperSlide>
              <SwiperImage src="/images/landing/1.png"/>
            </SwiperSlide>
            <SwiperSlide>
              <SwiperImage src="/images/landing/2.png"/>
            </SwiperSlide>
            <SwiperSlide>
              <SwiperImage src="/images/landing/3.png"/>
            </SwiperSlide>
          </JSSwiper>
        </Section>
      </SectionContainer>
      <SectionContainer backgroundColor={theme.colors.whiteText}>
        <Section>
          <Grid spacing={{xs: 4, sm: 4, md: 7}} container>
            <Grid xs={12} sm={12} md={6}>
              {isMobile ? "" : <YangbongImage/>}
            </Grid>
            <Grid xs={12} sm={12} md={6}>
              <SectionTitle mobileCenter={true} mobileMarginTop={100}>
                <Fade left>
                  養(기를 양) 蜂(벌 봉)
                </Fade>
              </SectionTitle>
              <SectionSubTitle mobileCenter={true} mobileMarginTop={20} marginTop={40}>
                사람과 자연 모두를 이롭게 하는 양봉.<br/>{isMobile ? <br/> : ""}
                그것이 우리가 걸어온 길이고 조선양봉이{isMobile ? <br/> : ""} 지속해서 나아가고자 하는 길입니다.<br/>
                <Logo src="/images/logo/logo-name.png"/>
                {isMobile ? <YangbongImage/> : ""}
              </SectionSubTitle>
              <SectionTitle mobileMarginTop={40}>
                자연숙성
              </SectionTitle>
              <SectionSubTitle mobileMarginTop={20} mobileMarginBottom={40} marginTop={40}>
                자연 숙성 방식은 꿀벌이 꽃에서 채취한 꿀을 오로지 벌의 날갯짓과 소화를 거쳐 숙성하는 자연 그대로의 방식을 뜻합니다.
              </SectionSubTitle>
              <Button onClick={() => window.open(SHOP_LINK, '_blank')}>제품 구매하기</Button>
            </Grid>
          </Grid>
        </Section>
      </SectionContainer>
    </>
  )
}

export default Index
