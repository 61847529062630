import React from "react";
import {PageTitle, Section, SectionContainer, SectionSubTitle} from "../assets/components/section";
import Grid from "@mui/material/Unstable_Grid2";
import {Fade} from 'react-reveal';
import {useTheme} from "styled-components";
import NewsCard from "../components/Card";
import {useNavigate} from "react-router-dom";

function News() {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <SectionContainer noCover={false} backgroundColor={theme.colors.whiteText}>
      <Section paddingTop={60}>
        <Grid spacing={{xs: 3, sm: 3}} container>
          <Grid md={12}>
            <Fade duration={1000} left>
              <PageTitle>
                News & Events
              </PageTitle>
            </Fade>
            <Fade duration={2000} top>
              <SectionSubTitle mobileMarginTop={20} marginBottom={10}>
                조선양봉의 다양한 소식들을 만나보세요.
              </SectionSubTitle>
            </Fade>
          </Grid>
          <Grid xs={12} sm={12} md={4}>
            <NewsCard image="/images/news/1.png" title="추석연휴"
                      content="이번 추석에도 조선양봉과 함께 달달한 추석 보내세요. 감사합니다." date="2023.09.27"
                      onClick={() => navigate("/news/1")}/>
          </Grid>
          <Grid xs={12} sm={12} md={4}>
            <NewsCard image="/images/news/2.png" title="조선양봉 롯데백화점 입점!"
                      content="이번 추석 롯데백화점 추석 마중 GIFT 특선 기획전에서 조선양봉으로 소중한 마음을 전하세요." date="2023.09.15"
                      onClick={() => navigate("/news/2")}/>
          </Grid>
          <Grid xs={12} sm={12} md={4}>
            <NewsCard image="/images/news/3.png" title="반려동물과 함께하는 이태원 최고의 플레이스 볼보이07에서 조선양봉을 경험해보세요."
                      content="‘조선양봉 꿀 하이볼’을 즐기실 수 있습니다." date="2023.06.27"
                      onClick={() => navigate("/news/3")}/>
          </Grid>
          <Grid xs={12} sm={12} md={4}>
            <NewsCard image="/images/news/4.png" title="핫플레이스! 신사 SUM'S에서 조선양봉 꿀 하이볼을 경험해보세요."
                      content="‘조선양봉 꿀 하이볼’을 경험해보세요" date="2023.05.11"
                      onClick={() => navigate("/news/4")}/>
          </Grid>
          <Grid xs={12} sm={12} md={4}>
            <NewsCard image="/images/news/5.png" title="최고의 스테이크 하우스 청담 볼트82에서 조선양봉을 만나보세요."
                      content="vault82에서 조선양봉의 제품이 전시 중입니다." date="2023.04.17"
                      onClick={() => navigate("/news/5")}/>
          </Grid>
          <Grid xs={12} sm={12} md={4}>
            <NewsCard image="/images/news/6.png" title="조선양봉 팝업스토어 공지."
                      content="성수동 논더레스에서 조선양봉의 첫 팝업스토어가 열립니다." date="2023.04.05"
                      onClick={() => navigate("/news/6")}/>
          </Grid>
          <Grid xs={12} sm={12} md={4}>
            <NewsCard image="/images/news/7.png" title="조선양봉의 첫 앰배서더 최나경 플루티스트님과 함께."
                      content="조선양봉과 함께 새해 복 많이 받으세요!" date="2023.01.01"
                      onClick={() => navigate("/news/7")}/>
          </Grid>
        </Grid>
      </Section>
    </SectionContainer>
  )
}

export default News;
