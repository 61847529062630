import React from "react";
import {Cover, CoverTextWrapper, CoverTitle} from "../assets/components/cover";
import Grid from "@mui/material/Unstable_Grid2";
import {
  PageTitle,
  Section,
  SectionContainer,
  SectionContent,
  SectionImage,
  SectionImageContent,
  SectionSubTitle,
  SectionTitle
} from "../assets/components/section";
import {useTheme} from "styled-components";
import Button from "../components/Button";
import {JSSwiper, SwiperImage} from "../assets/components/swiper";
import {SwiperSlide} from "swiper/react";
import {Fade} from 'react-reveal';
import {deviceSizes, SHOP_LINK} from "../constants/constant";
import {useMediaQuery} from 'react-responsive'

function Story(props) {
  const theme = useTheme();
  const isTablet = useMediaQuery({query: `(max-width: ${deviceSizes.tablet})`})
  const isMobile = useMediaQuery({query: `(max-width: ${deviceSizes.mobileL})`})

  return (
    <>
      <Cover backgroundUrl='/images/cover/story2.png' mobileHeight={400}>
        <CoverTextWrapper paddingTop={210} mobilePaddingTop={185}>
          <Fade duration={2000}>
            <CoverTitle>
              조선양봉은<br/>
              충분한 <b>자연 숙성</b>을 위해<br/>
              꿀의 종류별로<br/>
              <b>딱 1번의 채밀</b>만을<br/>
              고집합니다.
            </CoverTitle>
          </Fade>
        </CoverTextWrapper>
      </Cover>
      <SectionContainer paddingTop={60} paddingBottom={60}>
        <Section noPadding={isMobile} paddingBottom={20}>
          <Grid spacing={{xs: 4, sm: 4, md: 10}} container>
            <Grid xs={12} sm={12} md={5}>
              <SectionTitle>
                <Fade>
                  자연숙성
                </Fade>
              </SectionTitle>
            </Grid>
            <Grid style={{paddingTop: 0, paddingBottom: 60}} xs={12} sm={12} md={7}>
              <SectionContent bottom={true}>
                자연 숙성 방식은 꿀벌이 꽃에서 채취한 꿀을 오로지 벌의 날갯짓과 소화를 거쳐 숙성하는 자연 그대로의 방식을 뜻합니다.<br/>
                <br/>
                꿀은 열에 매우 취약합니다.<br/>
                <br/>
                대량생산을 위해 숙성의 시간을 갖지 못한 채 채밀되는 물 꿀은 수분함량이 높아 열을 이용한 인공적인 농축공정이 필요합니다.<br/>
                <br/>
                그리고 이 과정에서 열에 약한 꿀은 천연 효소와 항산화 성분, 프로폴리스, 벌 화분 등 수많은 영양분의 손실이 일어납니다. 특유의 맛과 향도 잃게 됩니다.<br/>
                <br/>
                인공적인 처리 없이 자연 그대로의 방식으로 숙성시킨 자연 숙성 벌꿀은 항산화 효과, 면역력 강화, 소화 기능 개선 등 본연의 풍부한 각종 영양성분이 그대로 유지되어 있으며 더욱 깊이 있는
                맛과 향을 선사합니다.
              </SectionContent>
            </Grid>
          </Grid>
          <JSSwiper
            marginTop={100}
            mobileMarginBottom={60}
            slidesPerView={isMobile ? 1.2 : isTablet ? 2.2 : 3}
            spaceBetween={isMobile ? 10 : isTablet ? 20 : 30}
            slidesOffsetBefore={isMobile ? 10 : 0}
            slidesOffsetAfter={isMobile ? 10 : 0}
          >
            <SwiperSlide>
              <SwiperImage src="/images/story/2.png"/>
            </SwiperSlide>
            <SwiperSlide>
              <SwiperImage src="/images/story/3.png"/>
            </SwiperSlide>
            <SwiperSlide>
              <SwiperImage src="/images/story/4.png"/>
            </SwiperSlide>
          </JSSwiper>
        </Section>
      </SectionContainer>
      <SectionContainer backgroundUrl='/images/story/5.png' noPadding={true}>
        <Section
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
          center
          paddingTop={60}
          paddingBottom={60}
          mobilePaddingBottom={60}
        >
          <SectionContent color={theme.colors.white} style={{textAlign: 'center'}}>
            조선양봉은 충분한 자연 숙성을 위해<br/>
            꿀의 종류별로 딱 1번의 채밀만을 고집합니다.<br/>
            365일 중 꿀을 생산하기 위해 허락된 시간,<br/>
            단 10일. 그 10일간 하늘이 허락하는 만큼의 <br/>
            꿀만을 정성스럽게 담습니다.
          </SectionContent>
          <Button
            onClick={() => window.open(SHOP_LINK, '_blank')}
            style={{
              marginTop: isMobile ? 40 : 80,
              width: 350,
              color: theme.colors.white,
              borderColor: theme.colors.white
            }}
          >
            제품 구매하기
          </Button>
        </Section>
      </SectionContainer>
      <SectionContainer backgroundColor={theme.colors.brown} paddingTop={100} paddingBottom={100}>
        <Section noPadding={true} mobilePaddingBottom={60}>
          <Grid container>
            <Grid xs={12} sm={12} md={5}>
              <Fade>
                <PageTitle color={theme.colors.white}>
                  금 성 옥 진
                </PageTitle>
              </Fade>
            </Grid>
            <Grid xs={12} sm={12} md={7}>
              <SectionSubTitle color={theme.colors.white} mobileMarginTop={40}>
                金 聲 玉 振<br/>
                금으로 만든 악기로 소리를 시작하여 옥으로 만든 악기로 조화롭게 마무리한다는 뜻으로 아카시아 꿀을 시작으로 밤 꿀 채밀로 마무리하는 한 해의 조화로운 과정을 뜻합니다.
              </SectionSubTitle>
            </Grid>
          </Grid>
          <Grid spacing={4} container alignItems="right">
            <Grid style={{paddingTop: 0, paddingBottom: 0}} xs={0} sm={0} md={2}></Grid>
            <Grid style={{textAlign: "left"}} xs={12} sm={12} md={5}>
              <SectionImage src="/images/story/6.png" mobileHeight={250}/>
              <SectionImageContent color={theme.colors.white} marginTop={20} marginBottom={40} mobileMarginTop={10}>
                <b>금(金) : 아카시아</b><br/>
                맑은 색과 은은한 향 그리고 부드러운 맛의 꿀로 우리에게 가장 친근한 꿀입니다.
              </SectionImageContent>
            </Grid>
            <Grid style={{textAlign: "left"}} xs={12} sm={12} md={5}>
              <SectionImage src="/images/story/7.png" mobileHeight={250}/>
              <SectionImageContent color={theme.colors.white} marginTop={20} marginBottom={40} mobileMarginTop={10}>
                <b>성(聲) : 야생화 꿀</b><br/>
                단일 밀원이 아닌 여로 꽃에서 채밀되는 벌꿀로 풍부한 영양성분과 다채로운 향, 맛을 지니고 있습니다.
              </SectionImageContent>
            </Grid>
            <Grid style={{paddingTop: 0, paddingBottom: 0}} xs={0} sm={0} md={2}></Grid>
            <Grid style={{textAlign: "left"}} xs={12} sm={12} md={5}>
              <SectionImage src="/images/story/8.png" mobileHeight={250}/>
              <SectionImageContent color={theme.colors.white} marginTop={20} marginBottom={40} mobileMarginTop={10}>
                <b>옥(玉) : 옻나무 꿀</b><br/>
                특수 밀원에 해당되는 옻나무꿀은 채밀량이 아주 적은 귀한 꿀입니다. 벌을 거쳐 꿀이 되는 과정에서 옻의 독성은 사라지고 영양은 더욱 풍부해지는 약꿀입니다.
              </SectionImageContent>
            </Grid>
            <Grid style={{textAlign: "left"}} xs={12} sm={12} md={5}>
              <SectionImage src="/images/story/9.png" mobileHeight={250}/>
              <SectionImageContent color={theme.colors.white} marginTop={20} marginBottom={40} mobileMarginTop={10}>
                <b>진(振) : 밤 꿀</b><br/>
                강력한 항산화 작용, 면연력 강화, 향균 작용 등의 도움을 주는 다양한 영양 성분이 풍부하며 최고의 품질을 자랑합니다. 색이 진하고 쓴맛이 특징입니다.
              </SectionImageContent>
            </Grid>
          </Grid>
        </Section>
      </SectionContainer>
    </>
  )
}

export default Story;
