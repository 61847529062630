import React from "react";
import {Section, SectionContainer} from "../assets/components/section";
import Grid from "@mui/material/Unstable_Grid2";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {NewsContent, NewsCover, NewsSubtitle, NewsSubtitle2, NewsTitle} from "../assets/routes/news";

function NewsPage3() {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <>
      <NewsCover backgroundUrl='/images/news/3-1.png'>
      </NewsCover>
      <SectionContainer noCover={false}>
        <Section>
          <Grid spacing={{xs: 12, sm: 12}} container>
            <Grid md={12}>
              <NewsSubtitle>
                News & Events
              </NewsSubtitle>
              <NewsSubtitle2>
                JUNE
              </NewsSubtitle2>
              <NewsTitle mobileMarginTop={10} marginTop={20}>
                반려동물과 함께하는 이태원 최고의 플레이스 볼보이07에서 조선양봉을 경험해보세요.
              </NewsTitle>
              <NewsContent mobileMarginTop={10} marginTop={40}>
                BALLBOY 07 x Josun Yangbong<br/>
                <br/>
                이태원과 녹사평 사이에 위치한 코지한 느낌의 펫프렌들리 스낵바 볼보이07.<br/>
                <br/>
                다양한 와인과 하이볼을 수제 미트볼과 함께 즐길 수 있는 곳입니다.<br/>
                <br/>
                볼보이07에서는 전시되어 있는 조선양봉의 제품과 조선양봉의 아카시아꿀을 이용한 ‘조선양봉 꿀 하이볼’을 즐기실 수 있습니다.<br/>
                <br/>
                마스코트인 귀여운 리트리버 공칠이가 반갑게 맞이해주는 볼보이07에서 시그니처 메뉴인 조선양봉 꿀 하이볼을 경험해보세요.<br/>
              </NewsContent>
            </Grid>
          </Grid>
        </Section>
      </SectionContainer>
    </>
  )
}

export default NewsPage3;
